import React from 'react';
import { WithIcon } from './WithIcon';

export const IconArticle = WithIcon(
  'Article',
  '0 0 20 20',
  <path
    transform="translate(0 3)"
    d="M13.333 14.333v1.334H0v-1.334h13.333zM20 9.667V11H0V9.667h20zM20 5v1.333H0V5h20zm0-4.667v1.334H0V.333h20z"
  />
);
